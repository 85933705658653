import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-216f63c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "h-100 d-flex align-items-center justify-content-center" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-lg-7 position-relative order-1 order-lg-0 athletes" }
const _hoisted_5 = { class: "col-12 col-lg-5 order-0 order-lg-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_card, {
        class: "card-wrapper p-0",
        title: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "leftImage", {}, undefined, true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ])
        ]),
        _: 3
      })
    ])
  ]))
}